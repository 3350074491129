/* Variables */

:root {
  --main-background: #25A18E;
  --alternate-background: #F9F9F9;
  --secondary-color: #0A1F24; /* dark green */
  --accent-color: #0A1F24; /* dark grey */
  --secondary-accent-color: grey;
  --light-text: #F7F8F8;
  --dark-text: #010606;
  --accent-text: #004E64;
  --alternate-accent-text: #00A5CF;
}

/* Styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cormorant Garamond', serif;
}

a {
  text-decoration: none;
  cursor: pointer;
}
